import React from 'react'
import "./Footer.css"
import konarworks from "../assets/konarworkslogo.svg";
const Footer = () => {
  return (
      <footer id='ankara-avukat' className='footer'>
      <div className="footer-content">
      <p>Bu web sitesi ve içindeki bilgiler, Türkiye Barolar Birliği'nin Meslek Kurallarına ve Avukatlık Reklam Yasağı Yönetmeliği'ne uygun şekilde tasarlanmıştır.<p>
      </p> Her hakkı saklıdır.</p>
        <p className="footer-text">&copy; {new Date().getFullYear()} Konar Hukuk & Danışmanlık. Ankara - Çankaya</p>
        <p>Web Tasarım & Yazılım </p><br />
          <a href="https://konarworks.com" className="footer-link" target="_blank">
            <img src={konarworks} alt="konarworks" />
          </a>
      </div>
       
      </footer>
  )
}

export default Footer